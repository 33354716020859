































































































































import router from "@/router";
import { Component, Vue } from "vue-property-decorator";
import { PaginatedList } from "@/api/models/common/paginatedList";
import {
  CreateEntreprise,
  Entreprise,
} from "@/api/models/options/entreprises/entreprise";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { BButton, BFormInput, BFormGroup, BForm } from "bootstrap-vue";
import { SecteurActivite } from "@/api/models/options/secteursActivites/secteurActivite";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min, max } from "@validations";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import TransfertDossiers from "./TransfertDossiers.vue";

@Component({
  components: {
    SearchableVueSelect,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    TransfertDossiers
  },
})
export default class SearchEntreprise extends Vue {
  required = required;
  min = min;
  max = max;

  selected: any = null;

  searchEntreprisesTotalCount = 0;
  searchEntreprisesItems: Entreprise[] = [];

  newEntreprise: CreateEntreprise = {};

  searchSecteursActivitesTotalCount = 0;
  searchSecteursActivitesItems: SecteurActivite[] = [];

  async searchEntreprise(params: any) {
    if (!params || params?.reset) {
      this.searchEntreprisesItems = this.searchEntreprisesItems?.filter(e => e == this.selected);
      this.searchEntreprisesTotalCount = this.searchEntreprisesItems.length;
    }

    await this.$http.ressifnet.entreprises
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<Entreprise>) => {
        this.searchEntreprisesTotalCount = response.totalCount;
        this.searchEntreprisesItems = this.searchEntreprisesItems!.concat(response.items.filter(ri => !this.searchEntreprisesItems!.some(lsi => lsi.id == ri.id)))
        // this.selected = this.searchEntreprisesItems.length == 1 ? this.searchEntreprisesItems[0] : null;
      });
  }

  async searchSecteursActivites(params: any) {
    if (!params || params?.reset) {
      this.searchSecteursActivitesItems = this.searchSecteursActivitesItems?.filter(e => e.id == this.newEntreprise.secteurActiviteId);
      this.searchSecteursActivitesTotalCount = this.searchSecteursActivitesItems.length;
    }

    await this.$http.ressifnet.secteursActivites
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<SecteurActivite>) => {
        this.searchSecteursActivitesTotalCount = response.totalCount;
        this.searchSecteursActivitesItems = this.searchSecteursActivitesItems!.concat(response.items.filter(ri => !this.searchSecteursActivitesItems!.some(lsi => lsi.id == ri.id)))
        // this.newEntreprise.secteurActiviteId = this.searchSecteursActivitesItems.length == 1 ? this.searchSecteursActivitesItems[0].id : undefined;
      });
  }

  goDetails(): any {
    router.push({
      name: "admin-entreprise-details",
      params: { id: this.selected.id },
    });
  }

  async confirmCreate(event: any) {
    event.preventDefault();

    (this.$refs.createFormValidation as InstanceType<typeof ValidationObserver>)
      .validate()
      .then(async (isSuccess: boolean) => {
        if (isSuccess) {
          await this.$http.ressifnet.entreprises.post(this.newEntreprise).then(
            async (response: any) => {
              this.$bvModal.hide("modal-post-entreprise");
              successAlert.fire({
                title: "Création d'une entreprise",
                text: "Modification effectuée avec succès",
              });

              router.push({
                name: "admin-entreprise-details",
                params: { id: response },
              });
            },
            (error: any) => {
              if (error.response.data.errors) {
                errorAlert.fire({
                  text: error.response.data.errors[
                    Object.keys(error.response.data.errors)[0]
                  ],
                });
              } else {
                this.$bvModal.hide("modal-post-entreprise");
                errorAlert.fire({
                  text: error.message,
                });
              }
            }
          );
        }
      });
  }
}
