












































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@validations";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { Entreprise } from "@/api/models/options/entreprises/entreprise";
import { Site } from "@/api/models/options/entreprises/site";
import { Secteur } from "@/api/models/options/entreprises/secteur";
import { TransfertDossiersSecteurSearchParams } from "@/api/models/dossiers/dossier";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { BSpinner } from "bootstrap-vue";
import { Branche } from "@/api/models/options/entreprises/branche";
import { Region } from "@/api/models/options/entreprises/region";

@Component({
  components: {
    SearchableVueSelect,
    ValidationObserver,
    ValidationProvider,
    BSpinner,
  },
})
export default class TransfertDossiers extends Vue {
  required = required;

  countLoading = false;
  updateLoading: boolean = false;
  isDossiers: boolean = true;

  model: any = {};

  listeEntreprises: Entreprise[] = [];
  totalCountEntreprise: number = 0;

  listeBranches: Branche[] = [];
  totalCountBranche: number = 0;

  listeRegions: Region[] = [];
  totalCountRegion: number = 0;

  listeSites: Site[] = [];
  totalCountSite: number = 0;

  listeSecteurs: Secteur[] = [];
  totalCountSecteur: number = 0;

  newListeEntreprises: Entreprise[] = [];
  newTotalCountEntreprise: number = 0;

  newListeBranches: Branche[] = [];
  newTotalCountBranche: number = 0;

  newListeRegions: Region[] = [];
  newTotalCountRegion: number = 0;

  newListeSites: Site[] = [];
  newTotalCountSite: number = 0;

  newListeSecteurs: Secteur[] = [];
  newTotalCountSecteur: number = 0;

  countDossiersSecteur = 0;
  countActionsCo: any = {
    actionsAdministratifCount: 0,
    actionsPreventionSanteTravailCount: 0
  };

  timeout: any;

  async confirmUpdateSecteur() {
    // TODO : change count action co
    this.isDossiers ? await this.getDossiersSecteurCount() : await this.getDossiersSecteurCount();
    const title = this.isDossiers
      ? "Transfert de dossiers"
      : "Transfert d'actions collectives";
    const text = this.isDossiers
      ? `Êtes-vous sûr de vouloir transférer ${
          this.countDossiersSecteur ? this.countDossiersSecteur : "0"
        } dossier(s) ?`
      : `Êtes-vous sûr de vouloir transférer ${this.countActionsCo.actionsAdministratifCount} action(s) relationnel partenariat et ${this.countActionsCo.actionsPreventionSanteTravailCount} actions de prévention en santé travail ?`;

    this.$swal({
      title: title,
      text: text,
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (val) => {
      if (val.isConfirmed) {
        if (this.isDossiers) {
          await this.updateDossiersSecteur();
        } else {
          await this.updateActionsCoSecteur();
        }
      }
    });
  }

  async updateActionsCoSecteur() {
    this.updateLoading = true;
    await this.$http.ressifnet.actionsCollectives
      .putEntreprise(this.model.entrepriseId, this.model)
      .then(
        async (response: any) => {
          successAlert.fire({
            title: "Transfert de dossiers",
            text: "Transfert effectué avec succès",
          });

          this.model = {};
          this.updateLoading = false;
          this.countActionsCo = {
            actionsCollectivesCount: 0,
            actionsPreventionSanteTravailCount: 0
          }
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      );
  }

  async updateDossiersSecteur() {
    this.updateLoading = true;
    await this.$http.ressifnet.dossiers
      .putSecteur(this.model.secteurId, this.model)
      .then(
        async (response: any) => {
          successAlert.fire({
            title: "Transfert de dossiers",
            text: "Transfert effectué avec succès",
          });

          this.model = {};
          this.updateLoading = false;
          this.countDossiersSecteur = 0
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      );
  }

  async intervalSecteurCount() {
    this.countLoading = true;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      if (this.isDossiers && this.model.secteurId) {
        await this.getDossiersSecteurCount();
      } else if (!this.isDossiers && this.model.entrepriseId) {
        await this.getActionsCollectivesEntrepriseCount()
      }
    }, 3000);
  }

  async getDossiersSecteurCount() {
    this.countLoading = true;
    await this.$http.ressifnet.dossiers
      .getDossiersSecteurCount(this.model.secteurId)
      .then(
        (response: number) => {
          this.countDossiersSecteur = response;
          this.countLoading = false;
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      );
  }

  async getActionsCollectivesEntrepriseCount() {
    this.countLoading = true;
    await this.$http.ressifnet.actionsCollectives
      .getActionsCollectivesEntrepriseCount(this.model.entrepriseId)
      .then(
        (response: any) => {
          this.countActionsCo = response;
          this.countLoading = false;
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      );
  }

  async searchEntreprises(params: any) {
    if (!params || params?.reset) {
      this.listeEntreprises = this.listeEntreprises?.filter(e => e.id == this.model.entrepriseId);
      this.totalCountEntreprise = this.listeEntreprises.length;
    }

    await this.$http.ressifnet.entreprises
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((res: PaginatedList<Entreprise>) => {
        this.totalCountEntreprise = res.totalCount;
        this.listeEntreprises = this.listeEntreprises!.concat(
          res.items.filter(
            (ri) => !this.listeEntreprises!.some((lsi) => lsi.id == ri.id)
          )
        );
        // this.model.entrepriseId = this.listeEntreprises.length === 1 ? this.listeEntreprises[0].id : undefined;
        // if(this.model.entrepriseId != undefined) this.searchSites(null);
      });
  }

  async searchNewEntreprises(params: any) {
    if (!params || params?.reset) {
      this.newListeEntreprises = this.newListeEntreprises?.filter(e => e.id == this.model.newEntrepriseId);
      this.newTotalCountEntreprise = this.newListeEntreprises.length;
    }

    await this.$http.ressifnet.entreprises
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((res: PaginatedList<Entreprise>) => {
        this.newTotalCountEntreprise = res.totalCount;
        this.newListeEntreprises = this.newListeEntreprises!.concat(
          res.items.filter(
            (ri) => !this.newListeEntreprises!.some((lsi) => lsi.id == ri.id)
          )
        );
        // this.model.newEntrepriseId = this.newListeEntreprises.length === 1 ? this.newListeEntreprises[0].id : undefined;
        // if(this.model.newEntrepriseId != undefined) this.searchNewSites(null);
      });
  }

  async searchBranches(params: any) {
    if (!params || params?.reset) {
      this.listeBranches = this.listeBranches?.filter(e => e.id == this.model.brancheId);
      this.totalCountBranche = this.listeBranches.length;
    }

    await this.$http.ressifnet.branches
      .paginatedList(
        this.model.entrepriseId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then(
        (res: PaginatedList<Branche>) => {
          this.totalCountBranche = res.totalCount;
          this.listeBranches = this.listeBranches!.concat(
            res.items.filter(
              (ri) => !this.listeBranches!.some((lsi) => lsi.id == ri.id)
            )
          );
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des branches",
          });
        }
      );
  }

  async searchNewBranches(params: any) {
    if (!params || params?.reset) {
      this.newListeBranches = this.newListeBranches?.filter(e => e.id == this.model.newBrancheId);
      this.newTotalCountBranche = this.newListeBranches.length;
    }

    await this.$http.ressifnet.branches
      .paginatedList(
        this.model.entrepriseId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then(
        (res: PaginatedList<Branche>) => {
          this.newTotalCountBranche = res.totalCount;
          this.newListeBranches = this.newListeBranches!.concat(
            res.items.filter(
              (ri) => !this.newListeBranches!.some((lsi) => lsi.id == ri.id)
            )
          );
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des branches",
          });
        }
      );
  }

  async searchRegions(params: any) {
    if (!params || params?.reset) {
      this.listeRegions = this.listeRegions?.filter(e => e.id == this.model.regionId);
      this.totalCountRegion = this.listeRegions.length;
    }

    await this.$http.ressifnet.regions
      .paginatedList(
        this.model.entrepriseId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then(
        (res: PaginatedList<Region>) => {
          this.totalCountRegion = res.totalCount;
          this.listeRegions = this.listeRegions!.concat(
            res.items.filter(
              (ri) => !this.listeRegions!.some((lsi) => lsi.id == ri.id)
            )
          );
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des régions",
          });
        }
      );
  }

  async searchNewRegions(params: any) {
    if (!params || params?.reset) {
      this.newListeRegions = this.newListeRegions?.filter(e => e.id == this.model.newRegionId);
      this.newTotalCountRegion = this.newListeRegions.length;
    }

    await this.$http.ressifnet.regions
      .paginatedList(
        this.model.entrepriseId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then(
        (res: PaginatedList<Region>) => {
          this.newTotalCountRegion = res.totalCount;
          this.newListeRegions = this.newListeRegions!.concat(
            res.items.filter(
              (ri) => !this.newListeRegions!.some((lsi) => lsi.id == ri.id)
            )
          );
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des régions",
          });
        }
      );
  }

  async searchSites(params: any) {
    if (!params || params?.reset) {
      this.listeSites = this.listeSites?.filter(e => e.id == this.model.siteId);
      this.totalCountSite = this.listeSites.length;
    }
    await this.$http.ressifnet.sites
      .paginatedList(
        this.model.entrepriseId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
        this.model.isDelie || !this.model.regionId ? "" : this.model.regionId,
        this.model.isDelie || !this.model.brancheId ? "" : this.model.brancheId
      )
      .then((res: PaginatedList<Site>) => {
        this.totalCountSite = res.totalCount;
        this.listeSites = this.listeSites!.concat(
          res.items.filter(
            (ri) => !this.listeSites!.some((lsi) => lsi.id == ri.id)
          ).map((x: Site) => <Site>{
            ...x,
             completeLabel: `${x.libelle} (${x.regionLibelle})`
        })
        );
        // this.model.siteId = this.listeSites.length === 1 ? this.listeSites[0].id : undefined;
        // if(this.model.siteId != undefined) this.searchSecteurs(null);
      });
  }

  async searchNewSites(params: any) {
    if (!params || params?.reset) {
      this.newListeSites = this.newListeSites?.filter(e => e.id == this.model.newSiteId);
      this.newTotalCountSite = this.newListeSites.length;
    }

    await this.$http.ressifnet.sites
      .paginatedList(
        this.model.newEntrepriseId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((res: PaginatedList<Site>) => {
        this.newTotalCountSite = res.totalCount;
        this.newListeSites = this.newListeSites!.concat(
          res.items.filter(
            (ri) => !this.newListeSites!.some((lsi) => lsi.id == ri.id)
          ).map((x: Site) => <Site>{
            ...x,
             completeLabel: `${x.libelle} (${x.regionLibelle})`
        })
        );
        
        // this.model.newSiteId = this.newListeSites.length === 1 ? this.newListeSites[0].id : undefined;
        // if(this.model.newSiteId != undefined) this.searchNewSecteurs(null);
      });
  }

  async searchSecteurs(params: any) {
    if (!params || params?.reset) {
      this.listeSecteurs = this.listeSecteurs?.filter(e => e.id == this.model.secteurId);
      this.totalCountSecteur = this.listeSecteurs.length;
    }

    await this.$http.ressifnet.secteurs
      .paginatedListForSite(
        this.model.entrepriseId,
        this.model.isDelie || !this.model.siteId ? "" : this.model.siteId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((res: PaginatedList<Secteur>) => {
        this.totalCountSite = res.totalCount;
        this.listeSecteurs = this.listeSecteurs!.concat(
          res.items.filter(
            (ri) => !this.listeSecteurs!.some((lsi) => lsi.id == ri.id)
          )
        );
        // this.model.secteurId = this.listeSecteurs.length === 1 ? this.listeSecteurs[0].id : undefined;
      });
  }

  async searchNewSecteurs(params: any) {
    if (!params || params?.reset) {
      this.newListeSecteurs = this.newListeSecteurs?.filter(e => e.id == this.model.newSecteurId);
      this.newTotalCountSecteur = this.newListeSecteurs.length;
    }

    await this.$http.ressifnet.secteurs
      .paginatedListForSite(
        this.model.newEntrepriseId,
        this.model.newSiteId ?? "",
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((res: PaginatedList<Secteur>) => {
        this.newTotalCountSite = res.totalCount;
        this.newListeSecteurs = this.newListeSecteurs!.concat(
          res.items.filter(
            (ri) => !this.newListeSecteurs!.some((lsi) => lsi.id == ri.id)
          )
        );
        // this.model.newSecteurId = this.newListeSecteurs.length === 1 ? this.newListeSecteurs[0].id : undefined;
      });
  }

  getSecteurLibelleById(secteurId: string, liste: Secteur[]) {
    return liste.find(s => s.id == secteurId)?.libelle
  }

  getSiteLibelleById(siteId: string, liste: Site[]) {
    return liste.find(s => s.id == siteId)?.libelle
  }

  getRegionLibelleById(regionId: string, liste: Region[]) {
    return liste.find(s => s.id == regionId)?.libelle
  }

  getBrancheLibelleById(brancheId: string, liste: Branche[]) {
    return liste.find(s => s.id == brancheId)?.libelle
  }

  getEntrepriseLibelleById(entrepriseId: string, liste: Entreprise[]) {
    return liste.find(s => s.id == entrepriseId)?.nom
  }

  onEntrepriseSelected(val: any) {
    if (val) {
      this.model.siteId = undefined;
      this.listeSites = [];
      this.model.secteurId = undefined;
      this.listeSecteurs = [];
    }
  }

  onNewEntrepriseSelected(val: any) {
    if (val) {
      this.model.newSiteId = undefined;
      this.newListeSites = [];
      this.model.newSecteurId = undefined;
      this.newListeSecteurs = [];
    }
  }

  onSiteSelected(val: any) {
    if (val) {
      this.model.secteurId = undefined;
      this.listeSecteurs = [];
    }
  }

  onNewSiteSelected(val: any) {
    if (val) {
      this.model.newSecteurId = undefined;
      this.newListeSecteurs = [];
    }
  }

  onClearEntreprise() {
    this.model.entrepriseId = undefined;
    this.model.siteId = undefined;
    this.model.secteurId = undefined;
    this.searchEntreprises(null);
    this.searchSites(null);
    this.searchSecteurs(null);
  }

  onClearNewEntreprise() {
    this.model.newEntrepriseId = undefined;
    this.model.newSiteId = undefined;
    this.model.newSecteurId = undefined;
    this.searchNewEntreprises(null);
    this.searchNewSites(null);
    this.searchNewSecteurs(null);
  }

  onClearSite() {
    this.model.siteId = undefined;
    this.model.secteurId = undefined;
    this.searchSites(null);
    this.searchSecteurs(null);
  }

  onClearNewSite() {
    this.model.newSiteId = undefined;
    this.model.newSecteurId = undefined;
    this.searchNewSites(null);
    this.searchNewSecteurs(null);
  }

  onClearSecteur() {
    this.model.secteurId = undefined;
    this.searchSecteurs(null);
  }

  onClearNewSecteur() {
    this.model.newSecteurId = undefined;
    this.searchNewSecteurs(null);
  }
}
